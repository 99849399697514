import { createContext, useState } from "react";

const FormulationsTabContext = createContext();

export default FormulationsTabContext;

export const FormulationsTabProvider = ({ children }) => {
  const [tabValue, setTabValue] = useState('LNP Samples');

  const contextData = {
    tabValue: tabValue,
    setTabValue: setTabValue,
  };

  return (
    <FormulationsTabContext.Provider value={contextData}>
      {children}
    </FormulationsTabContext.Provider>
  )
};