import { useContext } from "react";
import PropTypes from 'prop-types';

import NotificationContext from "../../context/NotificationContext";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';

import SearchBar from "../layout/SearchBar";

const valStyle = {
  fontSize: '40px',
  fontWeight: '600',
};

const QualityHeader = ({ 
  title, search, pageData, getUnVerifiedPlates, onGraphClick, 
  hasSpecialistPermission
}) => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);

  return(
    <Grid container spacing={1} style={{ marginTop: 5 }}>
      <Grid 
        item 
        xs={12} 
        sm={hasSpecialistPermission ? 3 : 4} 
        md={hasSpecialistPermission ? 4 : 5}
        lg={hasSpecialistPermission ? 5.2 : 6.2} 
        xl={hasSpecialistPermission ? 4.8 : 5.8}
      >
        <Typography 
          variant="h4" 
          component="div" 
          color="text.secondary" 
          style={valStyle}
        >
          {title}
        </Typography>
      </Grid>

      <Grid 
        item 
        xs={12} 
        sm={hasSpecialistPermission ? 5 : 6} 
        md={hasSpecialistPermission ? 4.4 : 5.4} 
        lg={4.6} 
        xl={5}
      >
        <SearchBar pageData={pageData} search={search} />
      </Grid>

      {
        hasSpecialistPermission &&
          <Grid item xs={4} sm={2} md={2} lg={1} xl={1}>
            <Button
              variant="contained"
              color="success"
              onClick={getUnVerifiedPlates}
              sx={{
                width: '100%'
              }}
            >
              Quality
            </Button>
          </Grid>
      }

      <Grid item xs={4} sm={1} md={0.8} lg={0.6} xl={0.5}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="View Graph" arrow>
            <IconButton
              size="large"
              color="black"
              onClick={onGraphClick}
            >
              <ScatterPlotIcon
                color="inherit"
                style={{
                  width: 35,
                  height: 35,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>

      <Grid item xs={4} sm={1} md={0.8} lg={0.6} xl={0.5}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="View notifications" arrow>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon 
                  color="action" 
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

QualityHeader.propTypes = {
  title: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  getUnVerifiedPlates: PropTypes.func.isRequired,
  onGraphClick: PropTypes.func.isRequired,
  hasSpecialistPermission: PropTypes.bool.isRequired,
};

export default QualityHeader;