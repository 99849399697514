import { useContext } from "react";
import PropTypes from 'prop-types';

import NotificationContext from "../../context/NotificationContext";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";

import TuneIcon from '@mui/icons-material/Tune';

import SearchBar from "../layout/SearchBar";

const valStyle = {
  fontSize: '40px',
  fontWeight: '600',
};

const QueryHeader = ({ 
  title, setFilterIsOpen, onDownload, pageData, search
}) => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);

  const handleFliterClick = () => {
   setFilterIsOpen();
  };

  const handleDownload = () => {
   onDownload();
  };

  return(
    <Grid container spacing={1} style={{ marginTop: 5 }}>
      <Grid item xs={12} sm={4} md={4.6} lg={5.2} xl={5.2}>
        <Typography 
          variant="h4" 
          component="div" 
          color="text.secondary" 
          style={valStyle}
        >
          {title}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={5} md={5} lg={4.75} xl={5}>
        <SearchBar pageData={pageData} search={search} />
      </Grid>

      <Grid item xs={4} sm={1} md={0.8} lg={0.6} xl={0.6}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="Filters" arrow>
            <IconButton
              size="large"
              color="black"
              onClick={handleFliterClick}
            >
              <TuneIcon
                color="inherit"
                style={{
                  width: 35,
                  height: 35,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>

      <Grid item xs={4} sm={1} md={0.8} lg={0.6} xl={0.6}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="Download query result" arrow>
            <IconButton
              size="large"
              color="black"
              onClick={handleDownload}
            >
              <FileDownloadIcon
                color="inherit"
                style={{
                  width: 35,
                  height: 35,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>

      <Grid item xs={4} sm={1} md={0.8} lg={0.6} xl={0.6}>
        <Box 
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: -1,
          }}
        >
          <Tooltip title="View notifications" arrow>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon 
                  color="action" 
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

QueryHeader.propTypes = {
  title: PropTypes.string.isRequired,
  setFilterIsOpen: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired,
};

export default QueryHeader;