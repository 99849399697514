import { createContext, useState, useEffect } from 'react';
import useAxios from '../utils/useAxios';
import { useNavigate } from 'react-router-dom';
import { resObj } from '../utils/constants';

const NotificationContext = createContext();

export default NotificationContext;


export const NotificationProvider = ({ children }) => {
  const api = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    getNotificationCount();
  }, [])

  const handleNotificationClick = () => {
    navigate('/notifications');
  };

  const [apiRes, setApiRes] = useState(resObj);
  const [notificationCount, setNotificationCount] = useState(0);

  const getNotificationCount = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/notifications/count/get');

      setApiRes({...apiRes, loading: false});
      if (response.status === 200) {
        setNotificationCount(response.data);;
      }
    } catch (err){
      if (err.response && err.response.status === 401) {
        navigate('/login');
      } else {
        setApiRes({
          ...apiRes,
          axiosError: true,
          errMsg: JSON.stringify(err.response?.data || {}).replace(/[{\[\]}"']/g, ''),
          errHeading: 'Notifications Get',
          loading: false,
        });
      }
    }
  };

  const contextData = {
    notificationCount: notificationCount,
    handleNotificationClick: handleNotificationClick,
    getNotificationCount: getNotificationCount,
  };

  return (
    <NotificationContext.Provider value={contextData}>
      {children}
    </NotificationContext.Provider>
  );
};