import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 340,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const QualityModalForm = ({ 
  show, setShow, verifyValues, setVerifyValues, formErrors, submitVerifyForm
}) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={show}>
        <Box sx={style} component="form" onSubmit={submitVerifyForm}>
          <Box>
            <Typography id="transition-modal-title" variant="h5" component="h3">
              <strong>{verifyValues.metricName}</strong>
            </Typography>
          </Box>
          
          <Box sx={{ marginTop: 1.5 }}>
            <FormControl fullWidth error={'qualityCheck' in formErrors}>
              <InputLabel id="quality-check-label">Quality Check</InputLabel>
              <Select
                labelId="quality-check-label"
                id="quality-check-select"
                value={verifyValues.qualityCheck}
                label="Quality Check"
                onChange={e => setVerifyValues({...verifyValues, qualityCheck: e.target.value})}
              >
                <MenuItem value={'Approve'}>Approve</MenuItem>
                <MenuItem value={'Reject'}>Reject</MenuItem>
              </Select>
              <FormHelperText>{formErrors.qualityCheck}</FormHelperText>
            </FormControl>
          </Box>

          <Box sx={{ marginTop: 1.5 }}>
            <TextField
              id="message"
              label="Message..."
              multiline
              minRows={2}
              maxRows={4}
              fullWidth
              value={verifyValues.message}
              onChange={e => setVerifyValues({...verifyValues, message: e.target.value})}
              helperText={formErrors.message}
              error={'message' in formErrors}
            />
          </Box>

          <Box sx={{ marginTop: 1.5 }}>
            <Button
              variant='contained'
              color='success'
              type="submit"
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

QualityModalForm.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  verifyValues: PropTypes.object.isRequired,
  setVerifyValues: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  submitVerifyForm: PropTypes.func.isRequired,
};

export default QualityModalForm;