import { useState, useEffect, useRef, useContext } from "react";

import useAxios from "../../utils/useAxios";
import { resObj, paginationVal } from "../../utils/constants";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';

import SideDrawer from "../../components/layout/SideDrawer";
import PageHeader from "../../components/layout/PageHeader";
import NotificationCard from "../../components/common/NotificationCard";
import Loader from "../../components/layout/Loader";
import SuccessAlert from "../../components/layout/SuccessAlert";
import NoData from "../../components/layout/NoData";

const NotificationPage = () => {
  const api = useAxios();
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  const [apiRes, setApiRes] = useState(resObj);
  const [notificationsList, setNotificationsList] = useState([]);
  const [tabValue, setTabValue] = useState('Success');
  const [notificationCount, setNotificationCount] = useState({successCount: 0, errorCount: 0});
  const pageData = useRef(paginationVal);

  useEffect(() => {
    getNotificationCount();
  }, []);

  useEffect(() => {
    pageData.current.pageNumber = 1;
    setNotificationsList([]);
    getNotifications();
  }, [tabValue]);

  const getNotificationCount = async type => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/notifications/type/count/get',{
        params: {
          notification_type: type,
        }
      });
      
      if (response.status === 200) {
        setNotificationCount({
          ...notificationCount,
          successCount: response.data.success_count,
          errorCount: response.data.error_count,
        });
        getNotifications('Success');
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Notifications Count Get',
        loading: false,
      });
    }
  };

  const getNotifications = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/notifications/list/get',{
        params: {
          page_number: +pageData.current.pageNumber,
          notification_type: tabValue,
        }
      });
      
      if (response.status === 200) {
        setNotificationsList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
        setApiRes({
          ...apiRes, 
          loading: false, 
          showAlert: true,
          successMsg: 'Notifications get successfully'
        });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Notifications Get',
        loading: false,
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getNotifications();
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <PageHeader title={'Notifications'} />

        <Box sx={{ margin: '0px 10px 0px 10px' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab label={`Success (${notificationCount.successCount})`} value='Success' />
            <Tab label={`Error (${notificationCount.errorCount})`} value='Error' />
          </Tabs>
        </Box>
        <div>
          {
            notificationsList.map((data, index) => (
              <NotificationCard key={index} data={data} />
            ))
          }

          {notificationsList.length === 0 && <NoData />}
          {(notificationsList.length !== 0 && pageData.current.pageCount !== 1) && <Pagination 
                            style={{marginTop: '10px', float: 'right'}} 
                            count={pageData.current.pageCount} 
                            onChange={handlePaginationClick}/>}

        </div>
      </div>
    </AutoLogout>
  );
};

export default NotificationPage;