import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { resObj } from "../../utils/constants";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Typography from "@mui/material/Typography";

import SideDrawer from "../../components/layout/SideDrawer";
import LNPSampleHeader from "../../components/analytics/LNPSampleHeader";
import Loader from "../../components/layout/Loader";
import ErrorModal from "../../components/layout/ErrorModal";
import FormulationForm from "../../components/analytics/FormulationForm";
import SubTable from "../../components/layout/SubTable";
import CommentModalForm from "../../components/analytics/CommentModalForm";
import SuccessAlert from "../../components/layout/SuccessAlert";
import CommentCard from "../../components/analytics/CommentCard";
import DeleteModal from "../../components/layout/DeleteModal";

const LNPSampleDetailPage = () => {
  const api = useAxios();
  const navigate = useNavigate();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-View Data']);
  }, [permisionsList]);

  const initialValues = {
    lnpSampleName: '',
    ionisableName: '',
    sterolName: '',
    neutralName: '',
    pegName: '',
    ionisableMolarRatio: '',
    sterolMolarRatio: '',
    neutralMolarRatio: '',
    pegMolarRatio: '',
    lipidTotalMolarConcentration: '',
    npRatio: '',
    parameterList: [],
    ribogreenList: [],
    dlsSummaryList: [],
    transfectionList: [],
    graphTitleList: [],
    graphDataList: [],
  };
  const [apiRes, setApiRes] = useState(resObj);
  const [values, setValues] = useState(initialValues);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState('');
  const [commentList, setCommentList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const docUrIArr = window.location.href.split('/');
  const lnpSampleId = docUrIArr[docUrIArr.length - 1];
  const backURL = '/formulations';
  const deleteModalTitle = 'LNP Sample Delete';
  const deleteModalMessage = `Are you sure you want to delete? 
    (All related informations, LNP Paramters, PSD Summary, PSD Graph, 
    Ribogreen, Transfection and Comments will be lost)`;

  const parameterHeading = [
    'Sample Replicate', 'Well ID', 'Labware Type', 'Tip Type', 'AQ Labware', 'AQ Well',
    'Mixing Cycles', 'Encapsulation Speed', 'Dilution Speed', 'LNP Plate Barcode'
  ];
  const ribogreenHeading = [
    'Sample Replicate', 'RG Plate', 'Well', 'Buffer', 'Raw FI', 'Curve m value','Curve c value', 'Curve r2 value', 
    'Sample Conc (μg/mL)', 'Expected Sample Conc (μg/mL)', 'Recovery (%)', 'EE (%)', 'Storage Condition', 'Status',
  ];
  const dlsSummaryHeading = [
    'Sample Replicate', 'Item', 'Data Quality', 'Intensity (Cnt/s)', 'Normalized Intensity (Cnt/s)', 'Well', 'Radius (nm)', 'Amplitude', 
    'Diffusion Coefficient (cm/s)', 'Diameter (nm)', 'Polydispersity (nm)', '%PD', 'PD Index', 'Storage Condition', 'Status',
  ];
  const transfectionHeading = [
    'Sample replicate', 'Barcode', 'Row', 'Column', 'Cells number objects', 'Transfection efficiency', 'Number analyzed fields', 
    'Condition', 'Concentration', 'Field correction', 'Avg cells number objects pos neg ctrls', 'Cells number percentage change', 
    'Green cells intensity mean normalised', 'Well', 'Plate ng', 'Storage Conditions', 'Status',
  ];

  useEffect(() => {
    getLNPSampleDetails();
  }, []);

  const getLNPSampleDetails = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/lnp/samples/detail/get', {
        params: { 
          lnp_sample_id: lnpSampleId,
        }
      });
      
      if (response.status === 200) {
        const data = response.data;
        setValues({
          ...values,
          lnpSampleName: data.lnp_sample.lnp_sample_name,
          ionisableName: data.lnp_sample.ionisable_name,
          sterolName: data.lnp_sample.sterol_name,
          neutralName: data.lnp_sample.neutral_name,
          pegName: data.lnp_sample.peg_name,
          ionisableMolarRatio: data.lnp_sample.ionisable_molar_ratio,
          sterolMolarRatio: data.lnp_sample.sterol_molar_ratio,
          neutralMolarRatio: data.lnp_sample.neutral_molar_ratio,
          pegMolarRatio: data.lnp_sample.peg_molar_ratio,
          lipidTotalMolarConcentration: data.lnp_sample.lipid_total_molar_concentration,
          npRatio: data.lnp_sample.np_ratio,
          parameterList: data.lnp_parameter,
          ribogreenList: data.ribogreen,
          dlsSummaryList: data.dls_summary,
          transfectionList: data.transfection,
          graphTitleList: data.graph_title_list,
          graphDataList: data.graph_data_list,
        });
        getComments();
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'LNP Sample Details Get',
        loading: false,
      });
    }
  };

  const addComment = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const formData = new FormData();
      formData.append("comment", comment);
      formData.append("lnp_sample_id", lnpSampleId);
      const response = await api.post('/api/user/add/lnp/sample/comment', formData);

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
        setComment('');
        getComments();
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Add Comment',
        loading: false,
      });
    }
  };

  const getComments = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/lnp/sample/comments/get', {
        params: { 
          lnp_sample_id: lnpSampleId,
        }
      });
      
      if (response.status === 200) {
        setCommentList(response.data);
        setApiRes({...apiRes, loading: false});
      }
    } catch (err){
      if (err.message === 'Request failed with status code 403') {
        setApiRes({...apiRes, loading: false});
      } else {
        setApiRes({
          ...apiRes,
          axiosError: true,
          errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
          errHeading: 'Comments Get',
          loading: false,
        });
      }
    }
  };

  const deleteLNPSample = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.delete(`/api/analytics/lnp/sample/delete/${lnpSampleId}`);

      if (response.status === 200) {
        setDeleteModalOpen(false);
        navigate('/formulations');
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: 'Sample deleted successfully',
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Sample Delete',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <LNPSampleHeader 
          title={values.lnpSampleName} 
          backURL={backURL} 
          setShowCommentModal={setShowCommentModal} 
          setDeleteModalOpen={setDeleteModalOpen} 
          isDeleteBtnVisible={permisionsList.includes('Analytics-Manage Data')}
          isCommentBtnVisible={permisionsList.includes('Analytics-Manage Comment')}
        />

        <FormulationForm values={values} />

        {commentList.length > 0 && <CommentCard commentList={commentList} />}

        { values.parameterList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>LNP Parameters ({values.parameterList.length})</strong>
            </Typography>
            <SubTable heading={parameterHeading} rows={values.parameterList} />
          </>
        }

        { values.ribogreenList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>Ribogreen ({values.ribogreenList.length})</strong>
            </Typography>
            <SubTable heading={ribogreenHeading} rows={values.ribogreenList} />
          </>
        }

        { values.transfectionList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>Transfection ({values.transfectionList.length})</strong>
            </Typography>
            <SubTable heading={transfectionHeading} rows={values.transfectionList} />
          </>
        }

        { values.dlsSummaryList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>PSD Summary ({values.dlsSummaryList.length})</strong>
            </Typography>
            <SubTable heading={dlsSummaryHeading} rows={values.dlsSummaryList} />
          </>
        }

        { values.graphDataList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>PSD Graph ({values.graphDataList.length})</strong>
            </Typography>
            <SubTable heading={values.graphTitleList} rows={values.graphDataList} />
          </>
        }

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />

        <CommentModalForm 
          show={showCommentModal} 
          setShow={setShowCommentModal} 
          comment={comment} 
          setComment={setComment} 
          addComment={addComment} 
        />

        <DeleteModal
          title={deleteModalTitle} 
          message={deleteModalMessage} 
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDelete={deleteLNPSample}
        />
      </div>
    </AutoLogout>
  );
};

export default LNPSampleDetailPage;