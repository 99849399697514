import { useState, useEffect, useContext } from "react";

import { resObj } from "../../utils/constants";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Typography from "@mui/material/Typography";

import SideDrawer from "../../components/layout/SideDrawer";
import TopBarWithBack from "../../components/layout/TopBarWithBack";
import Loader from "../../components/layout/Loader";
import ErrorModal from "../../components/layout/ErrorModal";
import SubTable from "../../components/layout/SubTable";
import SuccessAlert from "../../components/layout/SuccessAlert";

const AQControlDetailPage = () => {
  const api = useAxios();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-View Data', 'Analytics-Manage Data']);
  }, [permisionsList]);

  const initialValues = {
    aqControlName: '',
    ribogreenList: [],
  };
  const [apiRes, setApiRes] = useState(resObj);
  const [values, setValues] = useState(initialValues);

  const docUrIArr = window.location.href.split('/');
  const aqControlId = docUrIArr[docUrIArr.length - 1];
  const backURL = '/formulations';
  const ribogreenHeading = [
    'RG Plate', 'Well', 'Buffer', 'Raw FI', 'Curve m value','Curve c value', 'Curve r2 value', 'Sample Conc (μg/mL)', 
    'Expected Sample Conc (μg/mL)', 'Recovery (%)', 'EE (%)', 'Storage Condition', 'Status',
  ];

  useEffect(() => {
    getLNPSampleDetails();
  }, []);

  const getLNPSampleDetails = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/aqcontrol/detail/get', {
        params: { 
          aq_control_id: aqControlId,
        }
      });
      
      if (response.status === 200) {
        const data = response.data;
        setValues({
          ...values,
          aqControlName: data.aq_control.aq_control_name,
          ribogreenList: data.ribogreen,
        });
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'AQ Control Details Get',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <TopBarWithBack title={values.aqControlName} backURL={backURL} />

        <Typography 
          component="h5" 
          variant="h5" 
          color={'text.secondary'}
          sx={{ margin: 1.5 }}
        >
          <strong>Ribogreen ({values.ribogreenList.length})</strong>
        </Typography>
        <SubTable heading={ribogreenHeading} rows={values.ribogreenList} />

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default AQControlDetailPage;