import PropTypes from 'prop-types';

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const valStyle = {
  fontSize: '40px',
  fontWeight: '600',
};

const BasicCard = ({ subTitle, content }) => {
  return(
    <Card>
      <CardContent style={{ textAlign: 'center' }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {subTitle}
        </Typography>

        <Typography variant="h5" component="div" style={valStyle}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

BasicCard.propTypes = {
  subTitle: PropTypes.string.isRequired,
  content: PropTypes.number.isRequired,
};

export default BasicCard;