import { useEffect, useState, useContext, useRef } from "react";

import useAxios from "../../utils/useAxios";
import { resObj, paginationVal } from "../../utils/constants";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Pagination from '@mui/material/Pagination';

import SideDrawer from "../../components/layout/SideDrawer";
import Loader from "../../components/layout/Loader";
import ListHeader from "../../components/layout/ListHeader";
import CustomTable from "../../components/layout/CustomTable";
import SuccessAlert from "../../components/layout/SuccessAlert";
import NoData from "../../components/layout/NoData";

const LogListPage = () => {
  const api = useAxios();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Admin-View Logs List']);
  }, [permisionsList]);

  const tableHeading = ['SI No', 'Action', 'Action Details', 'User', 'Timestamp'];

  const pageData = useRef(paginationVal);
  const [apiRes, setApiRes] = useState(resObj);
  const [logsList, setLogsList] = useState([]);

  useEffect(() => {
    getLogs();
  }, []);

  const getLogs = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/logs/list/get', {
        params: { 
          search_term: pageData.current.searchTerm,
          page_number: +pageData.current.pageNumber,
        }
      });
      
      if (response.status === 200) {
        setApiRes({ ...apiRes, loading: false });
        setLogsList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Logs Get',
        loading: false,
      });
    }
  };

  const navigateToCreate = () => {
    return;
  };

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getLogs();
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <ListHeader 
          title="Logs" 
          handleClick={navigateToCreate} 
          search={getLogs}
          pageData={pageData}
          hasCreatePermission={false}
        />

        <CustomTable 
          heading={tableHeading} 
          rows={logsList} 
          navigateToEdit={navigateToCreate}
          setDeleteModalOpen={navigateToCreate}
          setId={navigateToCreate}
          pageData={pageData}
          idKey={'id'}
          isDeleteBtnVisible={false}
        />

        {logsList.length === 0 && <NoData />}

        {
          (logsList.length !== 0 && pageData.current.pageCount !== 1) 
            && 
          <Pagination 
            style={{marginTop: '10px', float: 'right'}} 
            count={pageData.current.pageCount} 
            onChange={handlePaginationClick}
          />
        }

      </div>
    </AutoLogout>
  );
};

export default LogListPage;