import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ search, pageData }) => {
  
  const handleSearch = (e) => {
    e.preventDefault();
    pageData.current.pageNumber = 1;
    search();
  };

  return(
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
      onSubmit={handleSearch}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={e => pageData.current.searchTerm = e.target.value}
      />
      <IconButton 
        type="button" 
        sx={{ p: '6px' }} 
        aria-label="search"
        onClick={handleSearch}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

SearchBar.propTypes = {
  search: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
};

export default SearchBar;