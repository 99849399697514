import { useState, useEffect, useContext } from "react";

import { resObj } from "../../utils/constants";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import SideDrawer from "../../components/layout/SideDrawer";
import Loader from "../../components/layout/Loader";
import TopBarWithBack from "../../components/layout/TopBarWithBack";
import ErrorModal from "../../components/layout/ErrorModal";
import QualityDetailForm from "../../components/qualitycontrol/QualityDetailForm";
import QualityModalForm from "../../components/qualitycontrol/QualityModalForm";
import QualityGraphModal from "../../components/qualitycontrol/QualityGraphModal";
import SuccessAlert from "../../components/layout/SuccessAlert";

const QualityControlDetailPage = () => {
  const api = useAxios();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission([
      'Quality-Quality Control Specialist', 
      'Quality-Quality Control View Data'
    ]);
  }, [permisionsList]);

  const docUrIArr = window.location.href.split('/');
  const plateId = docUrIArr[docUrIArr.length - 1];
  const backURL = '/quality/control';
  const tableHeading = [
    'LNP Sample Replicate', 'Value', 'Mean', 'UCL', 
    'LCL', 'UWCL', 'LWCL', 'Status'
  ];

  const initialValues = {
    plateName: '',
    controlList: [],
  };
  const verifyObj = {
    plateQualityMetricId: '',
    metricName: '',
    message: '',
    qualityCheck: '',
  };
  const graphObj = {
    metricName: '',
    qualityControl: '',
    controlList: [],
    graphList: [],
    uniqueQualityControlList: [],
    controlLimit: { mean: 0, ucl: 0, lcl: 0, uwcl: 0, lwcl: 0 }
  };
  const [apiRes, setApiRes] = useState(resObj);
  const [values, setValues] = useState(initialValues);
  const [graphIsOpen, setGraphIsOpen] = useState(false);
  const [verifyModalIsOpen, setVerifyModalIsOpen] = useState(false);
  const [verifyValues, setVerifyValues] = useState(verifyObj);
  const [graphValues, setGraphValues] = useState(graphObj);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    getLnpPlateQualityResult();
  }, []);

  const getLnpPlateQualityResult = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/quality/plate/detail/get', {
        params: { 
          plate_id: plateId,
        }
      });
      
      if (response.status === 200) {
        const data = response.data;
        setValues({
          ...values,
          plateName: data.lnp_plate.plate_name,
          controlList: data.control_list,
        });
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Plate Details Get',
        loading: false,
      });
    }
  };

  const handleGraphClick = (metricName, controlList) => {
    setGraphIsOpen(true);
    let uniqueQualityControlList = [];
    for (const row of controlList) {
      if (!uniqueQualityControlList.includes(row.lnp_sample_name)) {
        uniqueQualityControlList.push(row.lnp_sample_name)
      }
    }

    const qualityControl = uniqueQualityControlList.length > 0 ?
      uniqueQualityControlList[0] : '';

    setGraphValues({
      ...graphValues,
      metricName: metricName,
      qualityControl: qualityControl,
      uniqueQualityControlList: uniqueQualityControlList,
      controlList: controlList,
    });
  };

  useEffect(() => {
    const newArray = graphValues.controlList.filter(function (el) {
      return el.lnp_sample_name === graphValues.qualityControl;
    });
    
    if (newArray.length > 0) {      
      const limitObj = newArray[0];
      const statusList = ['Pass', 'Warning', 'Fail'];
      const graphColors = {
        'Pass': 'rgba(17, 183, 18, 1)',
        'Warning': 'rgba(243, 201, 9, 1)',
        'Fail': 'rgba(242, 10, 10, 1)',
      };
      const controlLimit = {
        mean: limitObj.mean,
        ucl: limitObj.ucl,
        lcl: limitObj.lcl,
        uwcl: limitObj.uwcl,
        lwcl: limitObj.lwcl,
      };

      const graphList = [];
      for (const status of statusList) {
        const data = generateGraphData(newArray, status);
        const graphObj = {
          label: status,
          data: data,
          backgroundColor: graphColors[status]
        };
        graphList.push(graphObj);
      }      
      
      setGraphValues({
        ...graphValues,
        controlLimit: controlLimit,
        graphList: graphList,
      });
    }
  }, [graphValues.qualityControl]);

  const generateGraphData = (dataList, status) => {
    const filterList = dataList.filter(function (el) {
      return el.status.includes(status);
    });
    
    const graphList = [];
    for (const row of filterList) {
      const passObj = {
        x: row.lnp_sample_id, 
        y: row.value, 
        label: row.lnp_sample_replicate,
      };
      graphList.push(passObj);
    }

    return graphList;
  }

  const handleVerifyClick = (id, metricName) => {
    setVerifyModalIsOpen(true);
    setVerifyValues({
      ...verifyValues,
      plateQualityMetricId: id,
      metricName: metricName,
    })
  };

  const submitVerifyForm = e => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const errors = {};
    if (!verifyValues.qualityCheck) {
      errors.qualityCheck = 'Quality Check is required!';
    }
    if (!verifyValues.message) {
      errors.message = 'Message is required!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      plateVerification();
    }
  }, [formErrors]);

  const plateVerification = async () => {
    try {
      setVerifyModalIsOpen(false);
      setApiRes({...apiRes, loading: true});
      const formData = new FormData();
      formData.append("plate_quality_metric_id", verifyValues.plateQualityMetricId);
      formData.append("quality_check", verifyValues.qualityCheck);
      formData.append("message", verifyValues.message);
      const response = await api.post('/api/quality/verify/plate', formData);

      if (response.status === 200) {
        getLnpPlateQualityResult();
        setVerifyValues(verifyObj);
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Plate Verification',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <TopBarWithBack title={values.plateName} backURL={backURL} />

        {
          values.controlList.map((row, index) => (
            <div key={index}>
              { row.control_list.length > 0 &&
                <QualityDetailForm 
                  key={index}
                  row={row} 
                  tableHeading={tableHeading} 
                  handleGraphClick={handleGraphClick} 
                  handleVerifyClick={handleVerifyClick}
                  hasSpecialistPermission={permisionsList.includes('Quality-Quality Control Specialist')}
                />
              }
            </div>
          ))
        }

        { 
          verifyModalIsOpen && 
            <QualityModalForm 
              show={verifyModalIsOpen}
              setShow={setVerifyModalIsOpen}
              verifyValues={verifyValues}
              setVerifyValues={setVerifyValues}
              formErrors={formErrors}
              submitVerifyForm={submitVerifyForm}
            />
        }

        { 
          graphIsOpen && 
            <QualityGraphModal 
              show={graphIsOpen}
              setShow={setGraphIsOpen}
              graphValues={graphValues}
              setGraphValues={setGraphValues}
            />
        }

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default QualityControlDetailPage;