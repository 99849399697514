import PropTypes from 'prop-types';

import { Scatter } from 'react-chartjs-2';
import { 
  Chart as ChartJS, CategoryScale, LinearScale,
  PointElement, Title, Tooltip, Legend 
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale, LinearScale, PointElement, Title, 
  Tooltip, Legend, annotationPlugin, zoomPlugin
);

const ScatterChart = ({ title, controlLimit, graphList }) => {
  const data = {
    datasets: graphList
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
      },
      y: {
        beginAtZero: false,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            return `Sample Replicate: ${tooltipItem.raw.label}, Value: ${tooltipItem.raw.y}`;
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy', // Allow panning in both directions
        },
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming with touch gestures
          },
          mode: 'xy', // Allow zooming in both directions
        },
      },
      annotation: {
        annotations: {
          mean: {
            type: 'line',
            yMin: controlLimit.mean,
            yMax: controlLimit.mean,
            borderColor: 'rgba(17, 183, 18, 0.4)',
            borderWidth: 2,
            label: {
              content: 'Mean',
              enabled: true,
              position: 'start',
            },
          },
          ucl: {
            type: 'line',
            yMin: controlLimit.ucl,
            yMax: controlLimit.ucl,
            borderColor: 'rgba(242, 10, 10, 0.4)',
            borderWidth: 2,
            label: {
              content: 'UCL',
              enabled: true,
              position: 'start',
            },
          },
          lcl: {
            type: 'line',
            yMin: controlLimit.lcl,
            yMax: controlLimit.lcl,
            borderColor: 'rgba(242, 10, 10, 0.4)',
            borderWidth: 2,
            label: {
              content: 'LCL',
              enabled: true,
              position: 'start',
            },
          },
          uwcl: {
            type: 'line',
            yMin: controlLimit.uwcl,
            yMax: controlLimit.uwcl,
            borderColor: 'rgba(243, 201, 9, 0.4)',
            borderWidth: 2,
            label: {
              content: 'UWCL',
              enabled: true,
              position: 'start',
            },
          },
          lwcl: {
            type: 'line',
            yMin: controlLimit.lwcl,
            yMax: controlLimit.lwcl,
            borderColor: 'rgba(243, 201, 9, 0.4)',
            borderWidth: 2,
            label: {
              content: 'LWCL',
              enabled: true,
              position: 'end',
            },
          },
        },
      },
    },
  };

  return <Scatter data={data} options={options} />;
};

ScatterChart.propTypes = {
  title: PropTypes.string.isRequired,
  controlLimit: PropTypes.object.isRequired,
  graphList: PropTypes.array.isRequired,
};

export default ScatterChart;
