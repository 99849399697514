import PropTypes from 'prop-types';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const MultipleSelectCheckmarks = ({ 
  label, dataList, values, setValues, type
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValues({
      ...values,
      [type]: typeof value === 'string' ? value.split(',') : value
    });
  };

  return (
    <Box>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id={type}>{label}</InputLabel>
        <Select
          labelId={type}
          id={type}
          name={type}
          multiple
          value={values[type]}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {dataList.map((url) => (
            <MenuItem key={url} value={url}>
              <Checkbox checked={values[type].indexOf(url) > -1} />
              <ListItemText primary={url} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

MultipleSelectCheckmarks.propTypes = {
  label: PropTypes.string.isRequired,
  dataList: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default MultipleSelectCheckmarks;