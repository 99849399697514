import { useState, useEffect, useContext } from "react";

import useAxios from "../../utils/useAxios";
import { useForm } from "../../utils/useForm";
import { resObj } from "../../utils/constants";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import PageHeader from "../../components/layout/PageHeader";
import UserProfileForm from "../../components/account/UserProfileForm";
import Loader from "../../components/layout/Loader";
import ErrorModal from "../../components/layout/ErrorModal";
import SuccessAlert from "../../components/layout/SuccessAlert";
import SideDrawer from "../../components/layout/SideDrawer";

const UserProfilePage = () => {
  const api = useAxios();

  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    roleName: '',
    password: '',
    confirmPassword: '',
  };

  const {values, setValues, handleInputChange} = useForm(initialValues);
  const [apiRes, setApiRes] = useState(resObj);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const submitForm = e => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const errors = {};
    const minLength = 8;
    const upperCasePattern = /[A-Z]/;
    const lowerCasePattern = /[a-z]/;
    const digitPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (values.password !== values.confirmPassword) {
      errors.password = 'Password does not match!';
      errors.confirmPassword = 'Password does not match!';
    }

    if (!values.password) {
      errors.password = 'Password is required!';
    } else if (values.password.length < minLength) {
      errors.password = `Password must be at least ${minLength} characters long!`;
    } else if (!upperCasePattern.test(values.password)) {
      errors.password = 'Password must contain at least one uppercase letter!';
    } else if (!lowerCasePattern.test(values.password)) {
      errors.password = 'Password must contain at least one lowercase letter!';
    } else if (!digitPattern.test(values.password)) {
      errors.password = 'Password must contain at least one digit!';
    } else if (!specialCharPattern.test(values.password)) {
      errors.password = 'Password must contain at least one special character!';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Password is required!';
    } else if (values.confirmPassword.length < minLength) {
      errors.confirmPassword = `Password must be at least ${minLength} characters long!`;
    } else if (!upperCasePattern.test(values.confirmPassword)) {
      errors.confirmPassword = 'Password must contain at least one uppercase letter!';
    } else if (!lowerCasePattern.test(values.confirmPassword)) {
      errors.confirmPassword = 'Password must contain at least one lowercase letter!';
    } else if (!digitPattern.test(values.confirmPassword)) {
      errors.confirmPassword = 'Password must contain at least one digit!';
    } else if (!specialCharPattern.test(values.confirmPassword)) {
      errors.confirmPassword = 'Password must contain at least one special character!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      changePassword();
    }
  }, [formErrors]);

  useEffect(() => {
    getUserProfile();
  }, [])

  const getUserProfile = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/user/profile/get');
      
      if (response.status === 200) {
        setApiRes({ ...apiRes, loading: false });
        setValues({
          ...values,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email,
          roleName: response.data.role,
        });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Profile Get',
        loading: false,
      });
    }
  };

  const changePassword = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.post('/api/user/change/password', {
        password: values.password,
        confirm_password: values.confirmPassword,
      });

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Change Password',
        loading: false,
      });
    }
    setValues({
      ...values,
      password: '',
      confirmPassword: '',
    });
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        { apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <PageHeader title={'User Profile'} />
        <UserProfileForm 
          values={values}
          handleInputChange={handleInputChange}
          submitForm={submitForm}
          formErrors={formErrors}
        />
        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default UserProfilePage;