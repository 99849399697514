import { useContext } from "react";

import NotificationContext from "../../context/NotificationContext";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";

const valStyle = {
  fontSize: '40px',
  fontWeight: '600',
};

const PageHeader = props => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);

  return(
    <Grid container spacing={1} style={{ marginTop: 1}}>
      <Grid item xs={10} sm={10.75} md={11} lg={11.25} xl={11.5}>
        <Typography variant="h4" component="div" color="text.secondary" style={valStyle}>
          {props.title}
        </Typography>
      </Grid>

      <Grid item xs={2} sm={1.25} md={1} lg={0.75} xl={0.5}>
        <Box 
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip title="View notifications" arrow>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon 
                  color="action"
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PageHeader;