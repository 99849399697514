import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import ScatterChart from '../layout/ScatterChart';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const arrowRemoveClass = {
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                           display: "none",
                         },
 "& input[type=number]": {
                           MozAppearance: "textfield",
                         },
};

const QualityMultiGraphModal = ({
  show, setShow, controlList, graphValues, setGraphValues, hoverId, 
  setHoverId, showGraphMetric, setShowGraphMetric, handleYAxisChange,
  graphFilterList
}) => {
  const handleClose = () => {
    setShow(false);
  };

  const handleExpandClick = metricName => {
    setShowGraphMetric(metricName);
  };

  return(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={show}>
        <Box sx={style}>
          <Box 
            sx={{ 
              marginTop: 1.5, 
              display: 'flex', 
              justifyContent: 'center' 
            }}
          >
            <FormControl sx={{
              width: '40%'
            }}>
              <InputLabel id="quality-control-label">Quality Control</InputLabel>
              <Select
                labelId="quality-control-label"
                id="quality-control-select"
                value={graphValues.qualityControl}
                label="Quality Control"
                onChange={e => setGraphValues({...graphValues, qualityControl: e.target.value})}
              >
                {
                  controlList.map((data, index) => (
                    <MenuItem value={data.id} key={index}>{data.control_name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>

          {
            (showGraphMetric !== '') 
              &&
            <CloseFullscreenIcon
              color='action'
              onClick={() => handleExpandClick('')}
              sx={{
                position: 'absolute',
                cursor: 'pointer',
                zIndex: 999,
                right: 20,
                top: '20%',
              }}
            />
          }
          
          <Grid container spacing={1} height={'100%'}>
            {graphValues.graphList.map((data, index) => (
              <Grid 
                item 
                xs={showGraphMetric === '' ? 4 : 12}
                key={index} 
                sx={{ 
                  position: 'relative',
                  maxHeight: showGraphMetric === '' ? '50%' : 500
                }}
              >
                {
                  (hoverId === index && showGraphMetric === '') 
                    && 
                  <OpenInFullIcon
                    color='action'
                    onMouseEnter={() => setHoverId(index)}
                    onMouseLeave={() => setHoverId(-1)}
                    onClick={() => handleExpandClick(data.metric_name)}
                    sx={{
                      position: 'absolute',
                      cursor: 'pointer',
                      zIndex: 999,
                      right: 2,
                      top: 20,
                      marginTop: index > 2 ? index > 5 ? 7 : 3.5 : 0,
                    }}
                  />
                }
                <div
                  onMouseEnter={() => setHoverId(index)}
                  onMouseLeave={() => setHoverId(-1)}
                  style={{
                    height: '100%',
                  }}
                >
                  {
                    (showGraphMetric === '' || showGraphMetric === data.metric_name) && 
                    <Fragment>
                      <Box 
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          marginTop: index > 2 ? index > 5 ? 7 : 3.5 : 0,
                        }}
                      >
                        <TextField 
                          id="standard-basic" 
                          label="Y Min" 
                          variant="standard" 
                          size='small'
                          type='number'
                          sx={arrowRemoveClass}
                          value={
                            graphFilterList.find(obj => obj.metricName === data.metric_name)?.yMin || ''
                          }
                          onChange={e => handleYAxisChange(e, data.metric_name, 'Lower')}
                        />

                        <TextField 
                          id="standard-basic" 
                          label="Y Max" 
                          variant="standard" 
                          size='small'
                          type='number'
                          sx={arrowRemoveClass}
                          value={
                            graphFilterList.find(obj => obj.metricName === data.metric_name)?.yMax || ''
                          }
                          onChange={e => handleYAxisChange(e, data.metric_name, 'Higher')}
                        />
                      </Box>
                      
                      <ScatterChart 
                        title={data.metric_name} 
                        controlLimit={data.control_limit} 
                        graphList={data.graphList} 
                      />
                    </Fragment>
                  }
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

QualityMultiGraphModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  controlList: PropTypes.array.isRequired,
  graphValues: PropTypes.object.isRequired,
  setGraphValues: PropTypes.func.isRequired,
  hoverId: PropTypes.number.isRequired, 
  setHoverId: PropTypes.func.isRequired,
  showGraphMetric: PropTypes.string.isRequired,
  setShowGraphMetric: PropTypes.func.isRequired,
  handleYAxisChange: PropTypes.func.isRequired,
  graphFilterList: PropTypes.array.isRequired,
};

export default QualityMultiGraphModal;