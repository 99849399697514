import { useState, useEffect, useRef, useContext } from "react";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import { resObj, paginationVal } from "../../utils/constants";
import AutoLogout from "../../utils/useAutoLogout";

import Pagination from '@mui/material/Pagination';

import SideDrawer from "../../components/layout/SideDrawer";
import PageHeader from "../../components/layout/PageHeader";
import ErrorModal from "../../components/layout/ErrorModal";
import CustomTable from "../../components/layout/CustomTable";
import SuccessAlert from "../../components/layout/SuccessAlert";
import Loader from "../../components/layout/Loader";
import NoData from "../../components/layout/NoData";

const S3NavigatorPage = () => {
  const api = useAxios();
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-View files in S3']);
  }, [permisionsList]);

  const [apiRes, setApiRes] = useState(resObj);
  const [filesList, setFilesList] = useState([]);

  const tableHeading = ['SI No', 'File Name', 'Last Modified', 'Size', 'Storage Class'];

  const pageData = useRef(paginationVal);

  useEffect(() => {
    getS3Files();
  }, []);

  const handlePaginationClick = (e, pageNumber) => {
    pageData.current.pageNumber = pageNumber;
    getS3Files();
  }

  const getS3Files = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/s3/files/get', {
        params: {
          page_number: +pageData.current.pageNumber,
        }
      });

      if (response.status === 200) {
        setFilesList(response.data.data);
        pageData.current.pageCount = response.data.page_count;
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'S3 Files Get',
        loading: false,
      });
    }
  };

  const navigateToEdit = () => {
    return;
  };

  return(
    <AutoLogout>
      {apiRes.loading && <Loader />}
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <PageHeader title={'S3 Navigator'} />

        <CustomTable 
          heading={tableHeading}
          rows={filesList}
          pageData={pageData}
          navigateToEdit={navigateToEdit}
          style={{ marginTop: '8px' }}
          setDeleteModalOpen={navigateToEdit}
          setId={navigateToEdit}
          idKey={'id'}
          isDeleteBtnVisible={false}
        />

        {filesList.length === 0 && <NoData />}

        {(filesList.length !== 0 && pageData.current.pageCount !== 1) && <Pagination 
                            style={{marginTop: '10px', float: 'right'}} 
                            count={pageData.current.pageCount} 
                            onChange={handlePaginationClick}/>}

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default S3NavigatorPage;