import { useState, useEffect, useContext } from "react";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import { resObj } from "../../utils/constants";
import AutoLogout from "../../utils/useAutoLogout";

import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import SideDrawer from "../../components/layout/SideDrawer";
import PageHeader from "../../components/layout/PageHeader";
import Loader from "../../components/layout/Loader";
import LineChart from "../../components/analytics/LineChart";
import ErrorModal from "../../components/layout/ErrorModal";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  height: 70,
  lineHeight: '40px',
}));

const valStyle = {
  fontSize: '30px',
  fontWeight: '600',
};

const theme = createTheme({ palette: { mode: 'light' } });

const TableStatisticsPage = () => {
  const api = useAxios();
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Admin-View Table Statistics']);
  }, [permisionsList]);

  const [apiRes, setApiRes] = useState(resObj);
  const graphInitValues = {
    labelList: [],
    graphList: [],
    latestRowCounts: [],
  };
  const [tableStats, setTableStats] = useState(graphInitValues);

  useEffect(() => {
    getTableStatistics();
  }, []);

  const generateFixedColors = (numColors, opacity = 1) => {
    const colors = [];
    const step = 360 / numColors;
  
    for (let i = 0; i < numColors; i++) {
      const hue = i * step;
      const color = `hsla(${hue}, 100%, 50%, ${opacity})`;
      colors.push(color);
    }
  
    return colors;
  };

  const getTableStatistics = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/table/statitics/get');

      if (response.status === 200) {
        const colors = generateFixedColors(response.data.graph_list.length);
        const data = response.data.graph_list.map((item, index) => {
          return {
            ...item,
            fill: false,
            borderColor: colors[index],
            tension: 0.1,
          }
        });        
        setTableStats((prev) => ({
          ...prev, 
          graphList: data, 
          labelList: response.data.label_list,
          latestRowCounts: response.data.latest_row_counts,
        }));        
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Table Statistics Get',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      {apiRes.loading && <Loader />}
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        <PageHeader title={'Table Statistics'} />

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ThemeProvider theme={theme}>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  bgcolor: 'background.default',
                  display: 'grid',
                  gridTemplateColumns: { 
                    xs: '1fr 1fr',
                    sm: '1fr 1fr 1fr',
                    md: '1fr 1fr 1fr 1fr 1fr',
                    lg: '1fr 1fr 1fr 1fr 1fr 1fr', 
                    xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr', 
                  },
                  gap: 1,
                }}
              >
                {tableStats.latestRowCounts.map((data, index) => (
                  <Item key={index} elevation={4}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">{data.table_name}</Typography>
                    <Typography variant="h5" component="div" style={valStyle}>
                      {data.row_count}
                    </Typography>
                  </Item>
                ))}
              </Box>
            </ThemeProvider>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box sx={{ textAlign: 'center'}}>
                  <Typography variant="h5" color={'text.secondary'}>
                    <strong>Table Statistics</strong>
                  </Typography>
                </Box>
                <LineChart tableStats={tableStats} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default TableStatisticsPage;