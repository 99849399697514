import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const PlateBarcodeItem = ({ type, barcode_list }) => {
  return(
    <Box>
      <FormControl component="fieldset">
        <FormLabel component="legend">{type}</FormLabel>
        <FormGroup aria-label="position" row>
          {
            barcode_list.map((data, index) => (
              <FormControlLabel
                value={data.barcode}
                control={
                  <Checkbox 
                    checked={data.is_active}
                    // onChange={handleChange}
                  />
                }
                label={data.barcode}
                labelPlacement="end"
                key={index}
              />
            ))
          }
        </FormGroup>
      </FormControl>
    </Box>
  );
};

PlateBarcodeItem.propTypes = {
  type: PropTypes.string.isRequired,
  barcode_list: PropTypes.array.isRequired,
};

export default PlateBarcodeItem;