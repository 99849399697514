import { useContext } from "react";
import { useNavigate } from 'react-router-dom';

import NotificationContext from "../../context/NotificationContext";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";

import styles from './ListHeader.module.css';

const TopBarWithBack = props => {
  const { notificationCount, handleNotificationClick } = useContext(NotificationContext);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(props.backURL)
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "white", color: "black", boxShadow: "0px 0px 0px 0px" }}>
        <Toolbar>
          <ArrowBackIcon 
            fontSize='large' 
            color='error' 
            className={styles.backBtn}
            onClick={handleBackClick}
          />
          <Typography component="h4" variant="h4" color={'text.secondary'}><strong>{props.title}</strong></Typography>

          <Box 
            justifyContent="center"
            alignItems="center"
            sx={{
              marginLeft: 'auto',
              marginRight: 0,
            }}
          >
            <Tooltip title="View notifications" arrow>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={handleNotificationClick}
              >
                <Badge badgeContent={notificationCount} color="error">
                  <NotificationsIcon 
                    color="action" 
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBarWithBack;