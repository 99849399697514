import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CommentModalForm = ({ show, setShow, comment, setComment, addComment }) => {
  const handleClose = () => {
    setShow(false);
  };

  const submitForm = e => {
    e.preventDefault();
    if (comment !== '') {
      addComment();
      handleClose();
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={show}>
        <Box sx={style} component="form" onSubmit={submitForm}>
          <Box>
            <Typography id="transition-modal-title" variant="h5" component="h3">
              <strong>Add Comment</strong>
            </Typography>
          </Box>
          
          <Box sx={{ marginTop: 1.5 }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Comment......"
              multiline
              minRows={4}
              maxRows={6}
              fullWidth
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </Box>

          <Box sx={{ marginTop: 1.5 }}>
            <Button
              variant='contained'
              color='success'
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

CommentModalForm.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
};

export default CommentModalForm;