import { SvgIcon } from '@mui/material';

function BarcodeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M3 2h1v20H3V2zm3 0h2v20H6V2zm3 0h1v20H9V2zm3 0h2v20h-2V2zm3 0h1v20h-1V2zm3 0h2v20h-2V2zm3 0h1v20h-1V2z" />
    </SvgIcon>
  );
}

export default BarcodeIcon;