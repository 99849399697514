import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import ErrorModal from '../../components/layout/ErrorModal';
import Loader from '../../components/layout/Loader';
import SuccessAlert from "../../components/layout/SuccessAlert";

import { baseURL } from '../../utils/constants';
import { useForm } from '../../utils/useForm';

import styles from "./Login.module.css";
import logo from "../../assets/images/logo.png";

const theme = createTheme();

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const resObj = {
    axiosError: false,
    errMsg: '',
    errHeading: '',
    successMsg: '',
    showAlert: false,
  };

  const docUrIArr = window.location.href.split('/');

  const initialValues = {
    token: docUrIArr[docUrIArr.length - 1],
    password: '',
    confirmPassword: '',
  };

  const [apiRes, setApiRes] = useState(resObj);
  const {values, setValues, handleInputChange} = useForm(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const submitForm = e => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  };

  const validate = () => {
    const errors = {};
    const minLength = 8;
    const upperCasePattern = /[A-Z]/;
    const lowerCasePattern = /[a-z]/;
    const digitPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (values.password !== values.confirmPassword) {
      errors.password = 'Password does not match!';
      errors.confirmPassword = 'Password does not match!';
    }

    if (!values.password) {
      errors.password = 'Password is required!';
    } else if (values.password.length < minLength) {
      errors.password = `Password must be at least ${minLength} characters long!`;
    } else if (!upperCasePattern.test(values.password)) {
      errors.password = 'Password must contain at least one uppercase letter!';
    } else if (!lowerCasePattern.test(values.password)) {
      errors.password = 'Password must contain at least one lowercase letter!';
    } else if (!digitPattern.test(values.password)) {
      errors.password = 'Password must contain at least one digit!';
    } else if (!specialCharPattern.test(values.password)) {
      errors.password = 'Password must contain at least one special character!';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Password is required!';
    } else if (values.confirmPassword.length < minLength) {
      errors.confirmPassword = `Password must be at least ${minLength} characters long!`;
    } else if (!upperCasePattern.test(values.confirmPassword)) {
      errors.confirmPassword = 'Password must contain at least one uppercase letter!';
    } else if (!lowerCasePattern.test(values.confirmPassword)) {
      errors.confirmPassword = 'Password must contain at least one lowercase letter!';
    } else if (!digitPattern.test(values.confirmPassword)) {
      errors.confirmPassword = 'Password must contain at least one digit!';
    } else if (!specialCharPattern.test(values.confirmPassword)) {
      errors.confirmPassword = 'Password must contain at least one special character!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      resetPassword();
    }
  }, [formErrors]);

  useEffect(() => {
    checkCodeExists();
  }, []);

  const checkCodeExists = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      await axios.get(`${baseURL}/api/user/check/verification/token`, {
        params: { token: values.token }
      });
    } catch (error) {
      navigate('/page-not-found');
    }
    setApiRes({...apiRes, loading: false});
  };

  const resetPassword = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await axios.post(`${baseURL}/api/user/reset/password`, {
        token: values.token,
        password: values.password,
        confirm_password: values.confirmPassword,
      });

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
        navigate('/login');
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{\[\]}"']/g, ''),
        errHeading: 'Reset Password',
        loading: false,
      });
    }
    setValues({
      ...values,
      password: '',
      confirmPassword: '',
    });
  };

  return(
    <div className={styles.background}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          {apiRes.loading && <Loader />}
          {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#fcfafa',
              padding: '50px',
              borderRadius: '20px',
              width: '450px'
            }}
          >
            <img src={logo} className={styles.logo} />
            <Box component="form" onSubmit={submitForm} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                autoFocus
                value={values.password}
                onChange={handleInputChange('password')}
                helperText={formErrors.password}
                error={'password' in formErrors}
              />

              <TextField
                margin="normal"
                fullWidth
                id="cPassword"
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={handleInputChange('confirmPassword')}
                helperText={formErrors.confirmPassword}
                error={'confirmPassword' in formErrors}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2}}
                className={styles.btnStyle}
              >
                Reset Password
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
    </div>
  );
};

export default ResetPasswordPage;