import { useState, useEffect, useContext } from "react";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import { resObj } from "../../utils/constants";
import AutoLogout from "../../utils/useAutoLogout";

import Typography from "@mui/material/Typography";

import SubTable from "../../components/layout/SubTable";
import ErrorModal from "../../components/layout/ErrorModal";
import SideDrawer from "../../components/layout/SideDrawer";
import Loader from "../../components/layout/Loader";
import SuccessAlert from "../../components/layout/SuccessAlert";
import TopBarWithBack from "../../components/layout/TopBarWithBack";
import NoData from "../../components/layout/NoData";

const EmptyWellsPage = () => {
  const api = useAxios();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-View Data']);
  }, [permisionsList]);

  const docUrIArr = window.location.href.split('/');
  const plateId = docUrIArr[docUrIArr.length - 1];
  const backURL = '/formulations';

  const ribogreenHeading = [
    'RG Plate', 'Well', 'Buffer', 'Raw FI', 'Curve m value','Curve c value', 'Curve r2 value', 
    'Sample Conc (μg/mL)', 'Expected Sample Conc (μg/mL)', 'Recovery (%)', 'EE (%)', 'Status',
  ];
  const dlsSummaryHeading = [
    'Item', 'Data Quality', 'Intensity (Cnt/s)', 'Normalized Intensity (Cnt/s)', 'Well', 'Radius (nm)', 'Amplitude', 
    'Diffusion Coefficient (cm/s)', 'Diameter (nm)', 'Polydispersity (nm)', '%PD', 'PD Index', 'Status',
  ];
  const transfectionHeading = [
    'Barcode', 'Row', 'Column', 'Cells number objects', 'Transfection efficiency', 'Number analyzed fields', 
    'Condition', 'Concentration', 'Field correction', 'Avg cells number objects pos neg ctrls', 
    'Cells number percentage change', 'Green cells intensity mean normalised', 'Well', 'Plate ng', 'Status',
  ];

  const initialValues = {
    plateName: '',
    ribogreenList: [],
    dlsSummaryList: [],
    transfectionList: [],
    graphTitleList: [],
    graphDataList: [],
  };
  const [apiRes, setApiRes] = useState(resObj);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    getLNPEmptyWellsDetails();
  }, []);

  const getLNPEmptyWellsDetails = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/empty/wells/detail/get', {
        params: { 
          plate_id: plateId,
        }
      });
      
      if (response.status === 200) {
        const data = response.data;
        setValues({
          ...values,
          plateName: data.lnp_plate.plate_name,
          ribogreenList: data.ribogreen,
          dlsSummaryList: data.dls_summary,
          graphTitleList: data.graph_title_list,
          graphDataList: data.graph_data_list,
          transfectionList: data.transfection,
        });
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'LNP Sample Details Get',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <TopBarWithBack title={values.plateName} backURL={backURL} />
        
        { values.ribogreenList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>Ribogreen ({values.ribogreenList.length})</strong>
            </Typography>
            <SubTable heading={ribogreenHeading} rows={values.ribogreenList} />
          </>
        }

        { values.transfectionList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>Transfection ({values.transfectionList.length})</strong>
            </Typography>
            <SubTable heading={transfectionHeading} rows={values.transfectionList} />
          </>
        }

        { values.dlsSummaryList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>PSD Summary ({values.dlsSummaryList.length})</strong>
            </Typography>
            <SubTable heading={dlsSummaryHeading} rows={values.dlsSummaryList} />
          </>
        }

        { values.graphDataList.length > 0 &&
          <>
            <Typography 
              component="h5" 
              variant="h5" 
              color={'text.secondary'}
              sx={{ margin: 1.5 }}
            >
              <strong>PSD Graph ({values.graphDataList.length})</strong>
            </Typography>
            <SubTable heading={values.graphTitleList} rows={values.graphDataList} />
          </>
        }

        {
          (
            values.ribogreenList.length === 0 && values.dlsSummaryList.length === 0 && 
            values.graphDataList.length === 0 && values.transfectionList.length === 0
          ) && <NoData />
        }

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default EmptyWellsPage;