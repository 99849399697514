import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import { resObj } from "../../utils/constants";
import AutoLogout from "../../utils/useAutoLogout";

import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import PageHeader from "../../components/layout/PageHeader";
import BasicCard from "../../components/layout/BasicCard";
import Loader from "../../components/layout/Loader";
import DoughnutChart from "../../components/analytics/DoughnutChart";
import BarChart from "../../components/analytics/BarChart";
import SideDrawer from "../../components/layout/SideDrawer";
import SuccessAlert from "../../components/layout/SuccessAlert";

const DashboardPage = () => {
  const api = useAxios();
  const navigate = useNavigate();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-Dashboard']);
  }, [permisionsList]);

  const dashboardValues = {
    lnpSamples: 0,
    lnpParameters: 0,
    psdSummary: 0,
    ribogreenOutput: 0,
    transfectionOutput: 0,
    doughnutObj: {},
    barchartLabelList: [],
    barchartValueList: [],
  };

  const [apiRes, setApiRes] = useState(resObj);
  const [dashboardData, setDashboardData] = useState(dashboardValues);
  const [doughnutGraphData, setDoughnutGraphData] = useState({
    labelList: [], valueList: []
  });
  const [doughnutTitle, setDoughnutTitle] = useState('');
  const [doughnutTitleList, setDoughnutTitleList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, index) => {
    setDoughnutTitle(doughnutTitleList[index]);
    handleMenuClose();
  };

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    setDoughnutTitleList(Object.keys(dashboardData.doughnutObj));
    setDoughnutTitle(doughnutTitleList[0]);
  }, [dashboardData.doughnutObj]);

  useEffect(() => {
    setDoughnutTitle(doughnutTitleList[0]);
  }, [doughnutTitleList]);

  useEffect(() => {
    const doughnutData = dashboardData?.doughnutObj[doughnutTitle];
    setDoughnutGraphData({
      labelList: doughnutData?.label_list,
      valueList: doughnutData?.value_list,
    });
  }, [doughnutTitle]);

  const getDashboard = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/dashboard/get');
      
      if (response.status === 200) {
        setApiRes({ ...apiRes, loading: false });
        setDashboardData({
          ...dashboardData,
          lnpSamples: response.data.lnp_samples,
          lnpParameters: response.data.lnp_parameters,
          psdSummary: response.data.psd_summary,
          ribogreenOutput: response.data.ribogreen_output,
          transfectionOutput: response.data.transfection_output,
          doughnutObj: response.data.dougnnut_graph_data,
          barchartLabelList: response.data.barchart_data.label_list,
          barchartValueList: response.data.barchart_data.value_list,
        });
      }
    } catch (err){
      if (err.response && err.response.status === 401) {
        navigate('/login');
      } else {
        setApiRes({
          ...apiRes,
          axiosError: true,
          errMsg: JSON.stringify(err.response?.data || {}).replace(/[{}[\]"']/g, ''),
          errHeading: 'Dashboard Get',
          loading: false,
        });
      }
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <PageHeader title={'Dashboard'} />

        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={2.4}>
            <BasicCard subTitle={'LNP Samples'} content={dashboardData.lnpSamples} />
          </Grid>

          <Grid item xs={6} sm={6} md={2.4}>
            <BasicCard subTitle={'LNP Sample Replicates'} content={dashboardData.lnpParameters} />
          </Grid>

          <Grid item xs={6} sm={6} md={2.4}>
            <BasicCard subTitle={'DLS Samples'} content={dashboardData.psdSummary} />
          </Grid>

          <Grid item xs={6} sm={6} md={2.4}>
            <BasicCard subTitle={'Ribogreen Samples'} content={dashboardData.ribogreenOutput} />
          </Grid>

          <Grid item xs={6} sm={6} md={2.4}>
            <BasicCard subTitle={'Transfection Samples'} content={dashboardData.transfectionOutput} />
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: 1}}>
          <Grid item xs={12} md={4.125}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h5" color={'text.secondary'}>
                    <strong>{doughnutTitle}</strong>
                  </Typography>

                  <ExpandMoreIcon 
                    onClick={e => handleMenuClick(e)}
                    sx={{
                      cursor: 'pointer',
                      color: 'text.secondary',
                    }}
                  />

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {doughnutTitleList.map((data, index) => (
                      <MenuItem onClick={e => handleMenuItemClick(e, index)} key={index}>{data}</MenuItem>
                    ))}
                  </Menu>
                </Box>

                <Box sx={{ marginTop: 2}}>
                  {doughnutGraphData.labelList && <DoughnutChart 
                                                    labelList={doughnutGraphData?.labelList} 
                                                    valueList={doughnutGraphData?.valueList} 
                                                  />}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={7.875}>
            <Card>
              <CardContent>
                <Box sx={{ textAlign: 'center'}}>
                  <Typography variant="h5" color={'text.secondary'}>
                    <strong>Unique lipid names</strong>
                  </Typography>
                </Box>

                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
                  <BarChart
                    labelList={dashboardData.barchartLabelList}
                    valueList={dashboardData.barchartValueList}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </AutoLogout>
  );
};

export default DashboardPage;