import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

const BarChart = ({ labelList, valueList }) => {
  const data = {
    labels: labelList,
    datasets: [{
      label: 'Show all',
      data: valueList,
      backgroundColor:
        [
          'rgba(72, 180, 248, 0.7)',
          'rgba(252, 102, 134, 0.7)',
          'rgba(252, 156, 68, 0.7)',
          'rgba(153, 102, 255, 0.7)',
        ],
      borderColor: [
        'rgb(54, 164, 236)',
        'rgb(252, 102, 134)',
        'rgb(252, 156, 68)',
        'rgb(153, 102, 255)',
      ],
      borderWidth: 1
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

BarChart.propTypes = {
  labelList: PropTypes.array.isRequired,
  valueList: PropTypes.array.isRequired,
};

export default BarChart;