import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const NotificationCard = props => {
  return(
    <Card sx={{ minWidth: 275 }} style={{margin: '10px'}}>
      <CardContent>
        <Typography variant="h6" component="div">
          {props.data.heading}
        </Typography>
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          {props.data.created_date}
        </Typography>
        <Typography whiteSpace="pre-wrap" variant="body2">
          {props.data.message}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NotificationCard;