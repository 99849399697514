import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#6c757d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UserTable = ({
  heading, rows, navigateToEdit, setDeleteModalOpen, setId,
  pageData, idKey, hasManagePermission, handleLockUser, style
}) => {

  const handleClick = id => {
    navigateToEdit(id);
  };

  const handleDeleteClick = id => {
    setId(id);
    setDeleteModalOpen(true);
  };

  return(
    <Box style={style}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {heading.map((item, index) => (
                <StyledTableCell align="center" key={index}>{item}</StyledTableCell>
              ))}
              {hasManagePermission && <StyledTableCell align="center">Lock</StyledTableCell>}
              {hasManagePermission && <StyledTableCell align="center">Action</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index} style={{ cursor: 'pointer' }}>
                <StyledTableCell 
                  align="center" 
                  key={`SI-${index}`} 
                  onClick={() => handleClick(row.id)}
                >
                  {((pageData.current.pageNumber - 1) *10) + (index + 1)}
                </StyledTableCell>
                {Object.keys(row).map((key, i) => (
                  (( (key !== idKey && !key.endsWith('_id')) && key !== 'is_locked')  && 
                    <StyledTableCell 
                      align="center" 
                      key={i} 
                      onClick={() => handleClick(row[idKey])}
                    >
                      {row[key]}
                    </StyledTableCell>
                  )
                ))}

                {hasManagePermission && 
                  <StyledTableCell align="center" key={`Switch-${index}`}>
                    <Switch 
                      checked={row.is_locked}
                      onChange={(e) => handleLockUser(e, row[idKey])} 
                    />
                  </StyledTableCell>
                }

                {hasManagePermission && 
                  <StyledTableCell 
                    align="center" 
                    key={`Delete-${index}`}
                  >
                    <DeleteOutlinedIcon onClick={() => handleDeleteClick(row[idKey])} />
                  </StyledTableCell>
                }
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

UserTable.propTypes = {
  heading: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  navigateToEdit: PropTypes.func.isRequired,
  setDeleteModalOpen: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  idKey: PropTypes.string.isRequired,
  hasManagePermission: PropTypes.bool.isRequired,
  handleLockUser: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default UserTable;