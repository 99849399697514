import PropTypes from 'prop-types';

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ labelList, valueList }) => {
  const generateFixedColors = (numColors, opacity = 1) => {
    const colors = [];
    const step = 360 / numColors; // Divide the color wheel into equal parts
  
    for (let i = 0; i < numColors; i++) {
      const hue = i * step;
      const color = `hsla(${hue}, 100%, 50%, ${opacity})`;
      colors.push(color);
    }
  
    return colors;
  };
  
  const backgroundColors = generateFixedColors(labelList.length, 0.4);
  const borderColors = generateFixedColors(labelList.length, 1);

  const data = {
    labels: labelList,
    datasets: [
      {
        label: '# of Votes',
        data: valueList,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start,'
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return(
    <div>
      <Doughnut data={data} options={options} />
    </div> 
  );
};

DoughnutChart.propTypes = {
  labelList: PropTypes.array.isRequired,
  valueList: PropTypes.array.isRequired,
};

export default DoughnutChart;
