import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const FormulationForm = ({ values }) => {
  return(
    <Box sx={{ margin: 1.5 }}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography color={'text.secondary'}>
            <strong>Ionisable: {`${values.ionisableName}`}</strong>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color={'text.secondary'}>
            <strong>Sterol: {`${values.sterolName}`}</strong>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color={'text.secondary'}>
            <strong>Neutral: {`${values.neutralName}`}</strong>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color={'text.secondary'}>
            <strong>PEG: {`${values.pegName}`}</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        <Grid item xs={3}>
          <Typography color={'text.secondary'}>
            <strong>Ionisable Percent: {`${values.ionisableMolarRatio}`}</strong>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color={'text.secondary'}>
            <strong>Sterol Percent: {`${values.sterolMolarRatio}`}</strong>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color={'text.secondary'}>
            <strong>Neutral Percent: {`${values.neutralMolarRatio}`}</strong>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color={'text.secondary'}>
            <strong>PEG Percent: {`${values.pegMolarRatio}`}</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        <Grid item xs={6}>
          <Typography color={'text.secondary'}>
            <strong>Lipid Total Molar Concentration: {`${values.lipidTotalMolarConcentration}`}</strong>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography color={'text.secondary'}>
            <strong>N:P: {`${values.npRatio}`}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

FormulationForm.propTypes = {
  values: PropTypes.object.isRequired,
};

export default FormulationForm;