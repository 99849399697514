import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import SideDrawer from "../../components/layout/SideDrawer";
import TopBarWithBack from "../../components/layout/TopBarWithBack";
import RoleForm from "../../components/user_mngmnt/RoleForm";
import ErrorModal from "../../components/layout/ErrorModal";
import SuccessAlert from "../../components/layout/SuccessAlert";
import Loader from "../../components/layout/Loader";

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { resObj } from "../../utils/constants";
import { useForm } from "../../utils/useForm";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

const theme = createTheme();

const RoleCreatePage = () => {
  const api = useAxios();
  const navigate = useNavigate();
  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  const backURL = '/roles';

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Admin-Role Create']);
  }, [permisionsList]);

  const initialValues = {
    roleName: '',
    description: '',
    permissions: [],
  };

  const [apiRes, setApiRes] = useState(resObj);
  const {values, setValues, handleInputChange} = useForm(initialValues);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(validate(values));
    setIsSubmit(true);
  };

  const validate = values => {
    const errors = {};
    if (!values.roleName) {
      errors.roleName = 'Name is required!';
    }
    if (!values.description) {
      errors.description = 'Description is required!';
    }
    if (selectedPermissions.length === 0) {
      errors.permissions = 'Permission is required!';
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      roleCreate();
    }
  }, [formErrors]);

  const handleClick = e => {
    const permissionId = e.target.value;
    if (e.target.checked) {
      setSelectedPermissions(arr => [...arr, +permissionId]);
    } else {
      setSelectedPermissions(arr => arr.filter(val => val !== +permissionId));
    }
  };

  useEffect(() => {
    getPemissions();
  }, []);

  const getPemissions = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/permissions/get');

      setApiRes({...apiRes, loading: false});
      if (response.status === 200) {
        setValues({
          ...values,
          permissions: response.data,
        });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'Permissions Get',
      });
    }
  };

  const roleCreate = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.post('/api/role/create', {
        role_name: values.roleName,
        description: values.description,
        permission_list: JSON.stringify(selectedPermissions),
      });

      if (response.status === 200) {
        setApiRes({
          ...apiRes,
          showAlert: true,
          successMsg: response.data,
          loading: false,
        });
        navigate('/roles');
      }
    } catch (err) {
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data),
        errHeading: 'Role Create',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
        {apiRes.loading && <Loader />}
        <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
          {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
          <TopBarWithBack title={'Role Create'} backURL={backURL} />
          <RoleForm
            values={values}
            handleInputChange={handleInputChange}
            handleClick={handleClick}
            selectedPermissions={selectedPermissions}
            submitForm={handleSubmit}
            formErrors={formErrors}
            isSubmitBtnVisible={permisionsList.includes('Admin-Role Create')}
          />
          <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
        </div>
      </ThemeProvider>
    </AutoLogout>
  );
};

export default RoleCreatePage;