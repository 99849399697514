import { useState, useEffect, useContext } from "react";

import { resObj } from "../../utils/constants";
import useAxios from "../../utils/useAxios";
import PermissionContext from "../../context/PermissionContext";
import DrawerContext from "../../context/DrawerContext";
import AutoLogout from "../../utils/useAutoLogout";

import Typography from "@mui/material/Typography";

import SideDrawer from "../../components/layout/SideDrawer";
import TopBarWithBack from "../../components/layout/TopBarWithBack";
import Loader from "../../components/layout/Loader";
import ErrorModal from "../../components/layout/ErrorModal";
import SubTable from "../../components/layout/SubTable";
import SuccessAlert from "../../components/layout/SuccessAlert";

const TransfectionControlDetailPage = () => {
  const api = useAxios();

  const { permisionsList, checkUserPermission } = useContext(PermissionContext);
  const { drawerValue, setDrawerValue } = useContext(DrawerContext);

  useEffect(() => {
    if(permisionsList.length > 0) checkUserPermission(['Analytics-View Data', 'Analytics-Manage Data']);
  }, [permisionsList]);

  const initialValues = {
    transfectionControlName: '',
    transfectionList: [],
  };
  const [apiRes, setApiRes] = useState(resObj);
  const [values, setValues] = useState(initialValues);

  const docUrIArr = window.location.href.split('/');
  const transfectionControlId = docUrIArr[docUrIArr.length - 1];
  const backURL = '/formulations';
  const transfectionHeading = [
    'Sample replicate', 'Barcode', 'Row', 'Column', 'Cells number objects', 'Transfection efficiency', 'Number analyzed fields', 
    'Condition', 'Concentration', 'Field correction', 'Avg cells number objects pos neg ctrls', 'Cells number percentage change', 
    'Green cells intensity mean normalised', 'Well', 'Plate ng', 'Storage Conditions', 'Status',
  ];

  useEffect(() => {
    getTransfectionControlDetails();
  }, []);

  const getTransfectionControlDetails = async () => {
    try {
      setApiRes({...apiRes, loading: true});
      const response = await api.get('/api/analytics/transfectioncontrol/detail/get', {
        params: { 
          transfection_control_id: transfectionControlId,
        }
      });
      
      if (response.status === 200) {
        const data = response.data;
        setValues({
          ...values,
          transfectionControlName: data.transfection_control.control_name,
          transfectionList: data.transfection,
        });
        setApiRes({ ...apiRes, loading: false });
      }
    } catch (err){
      setApiRes({
        ...apiRes,
        axiosError: true,
        errMsg: JSON.stringify(err.response.data).replace(/[{}[\]"']/g, ''),
        errHeading: 'AQ Control Details Get',
        loading: false,
      });
    }
  };

  return(
    <AutoLogout>
      <SideDrawer drawerValue={drawerValue} setDrawerValue={setDrawerValue} />
      {apiRes.loading && <Loader />}
      <div className="mainContainer" style={{ marginLeft: drawerValue.drawerWidth }}>
        {apiRes.showAlert && <SuccessAlert apiRes={apiRes} setApiRes={setApiRes} />}
        <TopBarWithBack title={values.transfectionControlName} backURL={backURL} />

        <Typography 
          component="h5" 
          variant="h5" 
          color={'text.secondary'}
          sx={{ margin: 1.5 }}
        >
          <strong>Transfection ({values.transfectionList.length})</strong>
        </Typography>
        <SubTable heading={transfectionHeading} rows={values.transfectionList} />

        <ErrorModal apiRes={apiRes} setApiRes={setApiRes} />
      </div>
    </AutoLogout>
  );
};

export default TransfectionControlDetailPage;