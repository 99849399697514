import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const SubTable = ({ heading, rows, excludeKeys=[] }) => {
  return(
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Table">
        <TableHead>
          <TableRow>
            {heading.map((title, index) => (
              <TableCell align="center" key={index}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {Object.keys(row).map((key, rowIndex) => (
                (!excludeKeys.includes(key) && <TableCell align="center" key={rowIndex}>{row[key]}</TableCell>)
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SubTable.propTypes = {
  heading: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  excludeKeys: PropTypes.array,
};

export default SubTable;