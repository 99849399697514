import { useState } from "react";

export const useForm = initialValues => {
  const [values, setValues] = useState(initialValues);

  const handleInputChange = name => event => {
    let value = '';
    if(name === 'dob' || name === 'startDate' || name === 'endDate') { value = event } else { value = event.target.value }
    setValues({
      ...values,
      [name]: value,
    });
  };

  return {
    values,
    setValues,
    handleInputChange,
  }
};

export const Form = props => {
  return (
    <form 
      onSubmit={props.onSubmit}
    >
      {props.children}
    </form>
  );
};