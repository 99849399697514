import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import MultipleSelectCheckmarks from '../layout/MultipleSelectCheckmarks';

const style = {
  position: 'absolute',
  top: '300px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '20px',
  paddingLeft: '40px',
  overflow: "auto"
};

const QueryFilterModal = ({ 
  search, open, setOpen, clearFilterForm, filterData, selectedDataObj,
  setSelectedDataObj, pageData, hasSpecialistPermission
}) => {
  const submitForm = e => {
    pageData.current.pageNumber = 1;
    search();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    clearFilterForm();
  };

  const handleShowAll = (event) => {
    setSelectedDataObj({
      ...selectedDataObj,
      showAll: event.target.checked,
    });
  };

  return(
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>  
          <Box sx={style} id='filterForm'>            
            <Grid container spacing={1}>
              <Grid container spacing={1} style={{ margin: '30px 20px 10px 25px'}}>
                <Grid container spacing={3}>
                  <Typography id="title" variant="h5" component="h5" color={'text.secondary'}>
                    <strong>Advanced Filters</strong>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <MultipleSelectCheckmarks
                    label={'Ionisable'}
                    dataList={filterData.ionisable_list}
                    values={selectedDataObj}
                    setValues={setSelectedDataObj}
                    type={'ionisable'}
                  />
                </Grid>

                <Grid item xs={6}>
                  <MultipleSelectCheckmarks
                    label={'Sterol'}
                    dataList={filterData.sterol_list}
                    values={selectedDataObj}
                    setValues={setSelectedDataObj}
                    type={'sterol'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} style={{ marginTop: 1 }}>
                <Grid item xs={6}>
                  <MultipleSelectCheckmarks
                    label={'Neutral'}
                    dataList={filterData.neutral_list}
                    values={selectedDataObj}
                    setValues={setSelectedDataObj}
                    type={'neutral'}
                  />
                </Grid>

                <Grid item xs={6}>
                  <MultipleSelectCheckmarks
                    label={'PEG'}
                    dataList={filterData.peg_list}
                    values={selectedDataObj}
                    setValues={setSelectedDataObj}
                    type={'peg'}
                  />
                </Grid>
              </Grid>

              {
                hasSpecialistPermission &&
                <Grid container spacing={1} style={{ marginTop: 1 }}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value={'Select All'}
                          control={
                            <Checkbox 
                              checked={selectedDataObj.showAll}
                              onChange={handleShowAll}
                            />
                          }
                          label={'Include Failed Data'}
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              }

              <Grid container spacing={1} style={{ marginTop: '0px'}}>
                <Grid item xs={2}>
                  <Button variant="outlined" color='secondary' onClick={handleClose}>Close</Button> 
                </Grid>

                <Grid item xs={2}>                
                  <Button variant="outlined" color='error' onClick={handleClear}>Clear</Button>
                </Grid>

                <Grid item xs={2}>
                  <Button 
                    variant="contained" 
                    className='taraPrimaryBtn'
                    onClick={submitForm}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

QueryFilterModal.propTypes = {
  search: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  clearFilterForm: PropTypes.func.isRequired,
  filterData: PropTypes.object.isRequired,
  selectedDataObj: PropTypes.object.isRequired,
  setSelectedDataObj: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  hasSpecialistPermission: PropTypes.bool.isRequired,
};

export default QueryFilterModal;