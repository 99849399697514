import { createContext, useState, useEffect } from "react";

const DrawerContext = createContext();

export default DrawerContext;

export const DrawerProvider = ({ children }) => {
  const minWidth = 70;
  const maxWidth = 245;

  const drawerObj = {
    isDrawerOpen: false,
    drawerWidth: minWidth,
  };
  const [drawerValue, setDrawerValue] = useState(drawerObj);

  useEffect(() => {
    drawerValue.isDrawerOpen ? 
      setDrawerValue({
        ...drawerValue,
        drawerWidth: maxWidth,
      }) : 
      setDrawerValue({
        ...drawerValue,
        drawerWidth: minWidth,
      });
  }, [drawerValue.isDrawerOpen]);

  const contextData = {
    drawerValue: drawerValue,
    setDrawerValue: setDrawerValue,
  };

  return (
    <DrawerContext.Provider value={contextData}>
      {children}
    </DrawerContext.Provider>
  )
};